// export const apiUrl = "http://localhost:3001";
const localPath = `http://localhost:3001`;
const devPath = `http://localhost:3001`;
const prodPath = `https://checklist-backend.hebidevelop.com`;
const environment = 'PROD'; // LOCAL, DEV, PROD
let apiUrl = localPath;
switch (environment) {
  case 'PROD':
    apiUrl = prodPath;
    break;
  case 'DEV':
    apiUrl = devPath;
    break;
  default:
    apiUrl = localPath;
    break;
}
export {apiUrl};
